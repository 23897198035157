<script setup>
import { ref } from 'vue';
import { Link } from '@inertiajs/vue3';
import ProductTypesMenu from './ProductTypesMenu.vue';
import MaterialsMenu from './MaterialsMenu.vue';
import SubMenuLinks from './SubMenuLinks.vue';

const emit = defineEmits(['close']);

const menu = ref(null);

const isSubMenuOpen = defineModel();

const handleSubMenu = () => {
    isSubMenuOpen.value = Boolean(
        document.querySelectorAll('[name="subMenu"][open]').length
    );
};

const closeSubMenus = () => {
    document.querySelectorAll('[name="subMenu"]').forEach(x => x.open = false);
    isSubMenuOpen.value = false;
};

const close = () => emit('close');

defineExpose({ handleSubMenu, closeSubMenus });
</script>

<template>
    <ul
        ref="menu"
        class="nav-menu"
    >
        <li>
            <Link
                :href="route('shop.index')"
                :class="{ active: route().current('shop.index') }"
                class="menu-item lg:btn lg:btn-sm lg:btn-primary lg:rounded-full lg:hover:!border-accent lg:hover:!bg-accent lg:hover:!text-accent-content"
                @click="close"
            >
                {{ $t('navigation.shop') }}
            </Link>
        </li>
        <li>
            <details
                name="subMenu"
                @toggle="handleSubMenu"
            >
                <summary class="menu-item">
                    {{ $t('navigation.product_range') }}
                </summary>
                <ProductTypesMenu @close="close" />
            </details>
        </li>
        <li>
            <details
                name="subMenu"
                @toggle="handleSubMenu"
            >
                <summary class="menu-item">
                    {{ $t('navigation.material') }}
                </summary>
                <MaterialsMenu @close="close" />
            </details>
        </li>
        <li>
            <Link
                :href="route('services')"
                :class="{ active: route().current('services') }"
                class="menu-item"
                @click="close"
            >
                {{ $t('navigation.services') }}
            </Link>
        </li>
        <li>
            <Link
                :href="route('about')"
                :class="{ active: route().current('about') }"
                class="menu-item"
                @click="close"
            >
                {{ $t('navigation.about') }}
            </Link>
        </li>
        <li>
            <Link
                :href="route('contact')"
                :class="{ active: route().current('contact') }"
                class="menu-item"
                @click="close"
            >
                {{ $t('navigation.support') }}
            </Link>
        </li>

        <SubMenuLinks
            class="lg:hidden mt-8"
            @close="close"
        />
    </ul>
</template>
