<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useForm } from '@inertiajs/vue3';
import SelectInput from '@/Components/SelectInput.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PinIcon from '@assets/icons/pin.svg';
import { useCountries } from '@/Composables/useCountries';
import { useShopData } from '@/Composables/useShopData';

const { countries, getCountryNameFromCode } = useCountries();
const shopData = computed(() => useShopData());
const destinationCountryCode = computed(
    () => shopData.value.destination_country_code,
);
const countryName = computed(() =>
    getCountryNameFromCode(destinationCountryCode.value),
);

const modal = ref(null);

const showModal = () => {
    modal.value?.showModal();
};

const closeModal = () => {
    modal.value?.close();
};

const form = useForm({
    destination_country_code: destinationCountryCode.value || 'at',
});

const submit = () => {
    form.put(route('shop.update-shop-data'), {
        preserveScroll: true,
        onSuccess: () => closeModal(),
    });
};

const checkForDestinationCountry = () => {
    if (!destinationCountryCode.value) {
        showModal();
    }
};

watch(destinationCountryCode, (newValue) => {
    form.destination_country_code = newValue;

    if (!newValue) {
        showModal();
    }
});

defineExpose({ showModal, closeModal });

onMounted(() => checkForDestinationCountry());
</script>

<template>
    <dialog ref="modal" class="modal modal-bottom sm:modal-middle">
        <div class="text-white modal-box bg-primary">
            <form v-if="destinationCountryCode" method="dialog">
                <button
                    class="absolute text-white btn btn-sm btn-circle btn-ghost right-2 top-2"
                >
                    ✕
                </button>
            </form>

            <form @submit.prevent="submit">
                <div
                    class="flex flex-col items-center justify-center space-y-6"
                >
                    <div
                        class="flex flex-col items-center justify-center text-lg font-medium text-center max-w-64"
                    >
                        <PinIcon class="w-auto h-16 mb-4" />
                        <i18n-t
                            v-if="destinationCountryCode"
                            keypath="checkout.destination_country_info"
                            scope="global"
                        >
                            <template #country>
                                <div class="underline">
                                    {{ countryName }}
                                </div>
                            </template>
                        </i18n-t>
                        <template v-else>
                            {{ $t('checkout.select_destination_country') }}
                        </template>
                    </div>

                    <InputLabel
                        :label="$t('checkout.destination_country')"
                        label-text-classes="text-white"
                    >
                        <SelectInput
                            v-model="form.destination_country_code"
                            class="text-base-content"
                        >
                            <option value="" disabled>
                                {{ $t('labels.select') }}
                            </option>
                            <option
                                v-for="country in countries"
                                :key="country.id"
                                :value="country.id"
                            >
                                {{ country.name }}
                            </option>
                        </SelectInput>
                    </InputLabel>

                    <button class="btn btn-white" @click="submit">
                        {{ $t('labels.save') }}
                    </button>
                </div>
            </form>
        </div>

        <form
            v-if="destinationCountryCode"
            method="dialog"
            class="modal-backdrop"
        >
            <button>close</button>
        </form>
    </dialog>
</template>
