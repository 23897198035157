<script>
export default {
    props: {
        speed: {
            type: [String, Number],
            default: 1,
        },
        delay: {
            type: [String, Number],
            default: 0.01,  // Workaround for Chrome timing bug
        },
        pause: Boolean,
    },
    data() {
        return {
            duration: 0,
            hovered: false,
            focused: false,
        };
    },
    mounted() {
        const content = this.$el.firstElementChild;
        const elementWidth = this.$el.clientWidth;
        const contentWidth = content.offsetWidth;
        const repeats = contentWidth ? Math.ceil(elementWidth / contentWidth) : 0;
        for (let i = 0; i < repeats; i++) {
            const clone = content.cloneNode(true);
            clone.ariaHidden = 'true';
            this.$el.append(clone);
        }
        this.duration = contentWidth * 0.014 / this.speed;
    },
}
</script>

<style>
@keyframes marquee {
    to {
        transform: translateX(-100%);
    }
}
</style>

<template>
    <div
        class="relative flex items-start overflow-hidden"
        :style="{
            '--marquee-animation': focused ? null : 'marquee',
            '--marquee-duration': duration +'s',
            '--marquee-delay': delay +'s',
            'animation-play-state': pause && (hovered || focused) ? 'paused' : 'running',
        }"
        @mouseover="hovered = true"
        @mouseout="hovered = false"
        @focusin="focused = true"
        @focusout="focused = false"
    >
        <div
            class="flex-none"
            style="
                animation: var(--marquee-animation) linear infinite;
                animation-duration: var(--marquee-duration); animation-delay: var(--marquee-delay);
                animation-play-state: inherit;
            "
        >
            <slot></slot>
        </div>
    </div>
</template>
