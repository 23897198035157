<script setup>
import { ref, computed } from 'vue';
import { Link } from '@inertiajs/vue3';
import ArrowRightBoldIcon from '@assets/icons/arrow_right_bold.svg';
import CartIcon from '@assets/icons/cart.svg';
import HeartIcon from '@assets/icons/heart.svg';
import ParcelIcon from '@assets/icons/parcel.svg';
import CogIcon from '@assets/icons/cog.svg';
import LogoutIcon from '@assets/icons/logout.svg';
import TrashIcon from '@assets/icons/trash.svg';
import { useCurrentUser } from '@/Composables/useCurrentUser';
import SubMenuLinks from './SubMenuLinks.vue';

const emit = defineEmits(['close']);

const user = computed(() => useCurrentUser());

const menu = ref(null);

const close = () => emit('close');
</script>

<template>
    <div
        ref="menu"
        class="sub-menu"
    >
        <div class="page-container lg:flex gap-40">
            <SubMenuLinks
                class="max-lg:hidden mb-8"
                @close="close"
            />
            <ul class="sub-menu-columns lg:space-y-4">
                <li class="border-accent max-lg:border-t max-lg:border-b max-lg:py-2">
                    <Link
                        class="flex items-center gap-2 hover:text-primary"
                        :href="route('cart.index')"
                        @click="close"
                    >
                        <CartIcon class="w-4 h-4 text-primary" />
                        {{ $t('navigation.cart') }}
                    </Link>
                </li>

                <li v-if="user" class="border-accent max-lg:border-b max-lg:py-2">
                    <Link
                        class="flex items-center gap-2 hover:text-primary"
                        :href="route('wishlist.index')"
                        @click="close"
                    >
                        <HeartIcon class="w-4 h-4 text-primary" />
                        {{ $t('navigation.wishlist') }}
                    </Link>
                </li>

                <li v-if="user" class="border-accent max-lg:border-b max-lg:py-2">
                    <Link
                        class="flex items-center gap-2 hover:text-primary"
                        :href="route('orders.index')"
                        @click="close"
                    >
                        <ParcelIcon class="w-4 h-4 text-primary" />
                        {{ $t('navigation.orders') }}
                    </Link>
                </li>

                <li v-if="user" class="border-accent max-lg:border-b max-lg:py-2">
                    <Link
                        class="flex items-center gap-2 hover:text-primary"
                        :href="route('profile.edit') + '#account-settings'"
                        @click="close"
                    >
                        <CogIcon class="w-4 h-4 text-primary" />
                        {{ $t('navigation.account_settings') }}
                    </Link>
                </li>

                <li v-if="user" class="border-accent max-lg:border-b max-lg:py-2">
                    <Link
                        method="post"
                        as="button"
                        class="flex items-center gap-2 hover:text-primary"
                        :href="route('logout')"
                        @click="close"
                    >
                        <LogoutIcon class="w-4 h-4 text-primary" />
                        {{ $t('navigation.logout') }}
                    </Link>
                </li>
                <li v-else class="border-accent max-lg:border-b max-lg:py-2">
                    <Link
                        class="flex items-center gap-2 hover:text-primary"
                        :href="route('register')"
                        @click="close"
                    >
                        <ArrowRightBoldIcon class="w-4 h-4 text-primary" />
                        {{ $t('navigation.register') }}
                    </Link>
                </li>
                <li v-if="!user" class="border-accent max-lg:border-b max-lg:py-2">
                    <Link
                        class="flex items-center gap-2 hover:text-primary"
                        :href="route('login')"
                        @click="close"
                    >
                        <ArrowRightBoldIcon class="w-4 h-4 text-primary" />
                        {{ $t('navigation.login') }}
                    </Link>
                </li>
            </ul>
        </div>
    </div>
</template>
