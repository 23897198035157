<script setup>
import { ref, computed } from 'vue';
import { usePage, Link } from '@inertiajs/vue3';
import SubMenuLinks from './SubMenuLinks.vue';

const emit = defineEmits(['close']);

const menu = ref(null);

const materials = computed(() => usePage().props.shop.materials.filter(x => x.isMain));

const close = () => emit('close');
</script>

<template>
    <div
        ref="menu"
        class="sub-menu"
    >
        <div class="page-container lg:flex gap-40">
            <SubMenuLinks
                class="max-lg:hidden mb-8"
                @close="close"
            />
            <ul class="sub-menu-columns lg:space-y-4">
                <template v-for="material in materials" :key="material.id">
                    <li class="border-accent max-lg:first:border-t max-lg:border-b max-lg:py-2">
                        <Link
                            class="flex items-center gap-2 lg:hover:text-primary"
                            :href="
                                route('shop.index', {
                                    materialIds: [material.id],
                                })
                            "
                            @click="close"
                        >
                            <div
                                v-if="material.icon"
                                class="w-4 h-4 shrink-0"
                                aria-hidden
                            >
                                <img :src="material.icon" class="w-full h-full" />
                            </div>
                            <div
                                v-else-if="material.image"
                                class="w-4 h-4 shrink-0"
                                aria-hidden
                            >
                                <img
                                    :src="material.image"
                                    class="object-cover w-full h-full"
                                />
                            </div>
                            {{ material.title }}
                        </Link>
                    </li>
                    <li
                        v-for="child in material.children"
                        :key="child.id"
                        class="border-accent max-lg:first:border-t max-lg:border-b max-lg:py-2"
                    >
                        <Link
                            class="flex items-center gap-2 lg:hover:text-primary"
                            :href="
                                route('shop.index', {
                                    materialIds: [material.id],
                                })
                            "
                            @click="close"
                        >
                            <div
                                v-if="material.icon"
                                class="w-4 h-4 shrink-0"
                                aria-hidden
                            >
                                <img :src="material.icon" class="w-full h-full" />
                            </div>
                            <div
                                v-else-if="child.image"
                                class="w-4 h-4 shrink-0"
                                aria-hidden
                            >
                                <img
                                    :src="child.image"
                                    class="object-cover w-full h-full"
                                />
                            </div>
                            {{ child.title }}
                        </Link>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>
