<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import { router } from '@inertiajs/vue3';

const props = defineProps({
    popup: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['close']);

const modal = ref(null);

const onClose = () => emit('close');

const onMarkAsSeen = () => {
    router.post(
        route('popups.mark-as-seen', { popup: props.popup }),
        {},
        { onFinish: onClose },
    );
};

onMounted(() => modal.value?.showModal());
onMounted(() => modal.value?.addEventListener('close', onClose));
onBeforeUnmount(() => modal.value?.removeEventListener('close', onClose));

watch(
    () => props.popup,
    () => modal.value?.showModal(),
);
</script>

<template>
    <dialog ref="modal" class="modal modal-bottom md:modal-middle">
        <div class="modal-box w-full md:w-11/12 !max-w-5xl p-0">
            <form method="dialog">
                <button class="z-50 absolute btn btn-sm btn-circle btn-ghost right-2 top-2">
                    ✕
                </button>
            </form>

            <div class="grid grid-cols-1 md:grid-cols-2 justify-between">
                <div class="p-8">
                    <img v-if="popup.image" :src="popup.image" alt="" class="w-full h-auto" />
                </div>
                <div class="flex flex-col gap-6 justify-between bg-base-200/30 text-primary p-8 relative">
                    <div>
                        <h3 class="text-2xl font-bold text-primary">
                            {{ popup.title }}
                        </h3>

                        <p class="rich-text-editor" v-html="popup.content" />
                    </div>

                    <div class="grid grid-cols-1 gap-y-3 gap-x-6 md:grid-cols-2">
                        <button class="btn btn-block" @click="onClose">
                            {{ $t('popup.close') }}
                        </button>
                        <button class="btn btn-primary btn-block" @click="onMarkAsSeen">
                            {{ $t('popup.dont_show_again') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <form method="dialog" class="modal-backdrop">
            <button>close</button>
        </form>
    </dialog>
</template>
