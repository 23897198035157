<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';

const { type } = defineProps({
    type: {
        type: String,
    },
    message: {
        type: String,
        default: '',
    },
});

const emit = defineEmits(['hide']);

const timeout = ref(null);

onMounted(() => {
    timeout.value = setTimeout(() => {emit('hide')}, 10000);
});
onUnmounted(() => {
    clearTimeout(timeout.value);
});

const icon = computed(() => {
    switch (type) {
        case 'success':
            return 'M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z';
        case 'info':
            return 'M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z';
        case 'warning':
            return 'M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z';
        case 'error':
            return 'M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z';
        default:
            return '';
    }
});
</script>

<template>
    <div
        role="alert"
        class="sm:max-w-sm w-full alert relative shadow-lg"
        :class="'alert-' + type + ' border-' + type + '-content'"
    >
        <div class="w-full text-left text-sm flex items-center justify-start gap-2 pr-4">
            <svg
                v-if="icon"
                xmlns="http://www.w3.org/2000/svg"
                class="w-6 h-6 stroke-current shrink-0"
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    :d="icon"
                />
            </svg>
            <span>{{ message }}</span>
        </div>
        <button
            class="absolute btn-sm right-2 top-2 text-white/70 hover:text-primary-content"
            @click="emit('hide')"
        >
            ✕
        </button>
    </div>
</template>
