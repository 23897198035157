<script setup>
import Toast from '@/Components/Toast.vue';
import { router, usePage } from '@inertiajs/vue3';
import { ref, computed, watch, Transition } from 'vue';

const flash = computed(() => usePage().props.flash);

const success = ref(flash.value.success || null);
const info = ref(flash.value.info || null);
const warning = ref(flash.value.warning || null);
const error = ref(flash.value.error || null);
const status = ref(flash.value.status || null);

const reloadFlash = () => router.reload({ only: ['flash'] });

const onHideSuccess = () => {
    success.value = null;
    reloadFlash();
};
const onHideInfo = () => {
    info.value = null;
    reloadFlash();
};
const onHideWarning = () => {
    warning.value = null;
    reloadFlash();
};
const onHideError = () => {
    error.value = null;
    reloadFlash();
};
const onHideStatus = () => {
    status.value = null;
    reloadFlash();
};

watch(
    () => flash.value,
    (newValue) => {
        success.value = newValue.success || null;
        info.value = newValue.info || null;
        warning.value = newValue.warning || null;
        error.value = newValue.error || null;
        status.value = newValue.status || null;
    },
    { deep: true },
);
</script>

<template>
    <div class="fixed bottom-0 right-0 w-full z-40 p-4 flex justify-end">
        <Transition
          enter-from-class="translate-x-[100%] opacity-0"
          leave-to-class="translate-x-[100%] opacity-0"
          enter-active-class="transition duration-300"
          leave-active-class="transition duration-300"
        >
            <Toast v-if="success" type="success" :message="success" @hide="onHideSuccess" />
        </Transition>
        <Transition
          enter-from-class="translate-x-[100%] opacity-0"
          leave-to-class="translate-x-[100%] opacity-0"
          enter-active-class="transition duration-300"
          leave-active-class="transition duration-300"
        >
            <Toast v-if="info" type="info" :message="info" @hide="onHideInfo" />
        </Transition>
        <Transition
            enter-from-class="translate-x-[100%] opacity-0"
            leave-to-class="translate-x-[100%] opacity-0"
            enter-active-class="transition duration-300"
            leave-active-class="transition duration-300"
        >
            <Toast v-if="warning" type="warning" :message="warning" @hide="onHideWarning" />
        </Transition>
        <Transition
            enter-from-class="translate-x-[100%] opacity-0"
            leave-to-class="translate-x-[100%] opacity-0"
            enter-active-class="transition duration-300"
            leave-active-class="transition duration-300"
        >
            <Toast v-if="error" type="error" :message="error" @hide="onHideError" />
        </Transition>
        <Transition
            enter-from-class="translate-x-[100%] opacity-0"
            leave-to-class="translate-x-[100%] opacity-0"
            enter-active-class="transition duration-300"
            leave-active-class="transition duration-300"
        >
            <Toast v-if="status" :message="status" @hide="onHideStatus" />
        </Transition>
    </div>
</template>
