<template>
    <div class="flex max-md:flex-col gap-4 md:rounded-box md:p-12 2xl:p-32 md:bg-primary">
        <article class="flex-1 card bg-primary text-primary-content">
            <div class="card-body min-h-64 justify-between gap-8">
                <div class="font-mono text-sm">
                    01 Store Wels
                </div>
                <h1 class="font-medium text-2xl lg:text-3xl">
                    <span v-html="$page.props.globals.wels_address"></span>
                </h1>
                <div class="font-mono text-sm">
                    <div class="border-t py-3 2xl:py-6">
                        <a
                            :href="'mailto:'+ $page.props.globals.wels_email"
                            class="block text-accent hover:text-inherit transition-colors"
                            v-html="$page.props.globals.wels_email"
                        ></a>
                    </div>
                    <div class="border-t py-3 2xl:py-6">
                        <a
                            :href="'tel:'+ $page.props.globals.wels_telephone.replace(/\(\d+\)|[^+\d]/g, '')"
                            class="block text-accent hover:text-inherit transition-colors"
                            v-html="$page.props.globals.wels_telephone"
                        ></a>
                    </div>
                    <div class="border-t py-3 2xl:py-6">
                        <a
                            :href="$page.props.globals.wels_map_link_href"
                            class="block text-accent hover:text-inherit transition-colors"
                            v-html="$page.props.globals.wels_map_link_text"
                        ></a>
                    </div>
                    <div class="border-t py-3 2xl:py-6">
                        <span v-html="$page.props.globals.wels_info"></span>
                    </div>
                </div>
            </div>
        </article>
        <article class="flex-1 card bg-primary text-primary-content">
            <div class="card-body min-h-64 justify-between gap-8">
                <div class="font-mono text-sm">
                    02 Store Wien
                </div>
                <h1 class="font-medium text-2xl lg:text-3xl">
                    <span v-html="$page.props.globals.wien_address"></span>
                </h1>
                <div class="font-mono text-sm">
                    <div class="border-t py-3 2xl:py-6">
                        <a
                            :href="'mailto:'+ $page.props.globals.wien_email"
                            class="block text-accent hover:text-inherit transition-colors"
                            v-html="$page.props.globals.wien_email"
                        ></a>
                    </div>
                    <div class="border-t py-3 2xl:py-6">
                        <a
                            :href="'tel:'+ $page.props.globals.wien_telephone.replace(/\(\d+\)|[^+\d]/g, '')"
                            class="block text-accent hover:text-inherit transition-colors"
                            v-html="$page.props.globals.wien_telephone"
                        ></a>
                    </div>
                    <div class="border-t py-3 2xl:py-6">
                        <a
                            :href="$page.props.globals.wien_map_link_href"
                            class="block text-accent hover:text-inherit transition-colors"
                            v-html="$page.props.globals.wien_map_link_text"
                        ></a>
                    </div>
                    <div class="border-t py-3 2xl:py-6">
                        <span v-html="$page.props.globals.wien_info"></span>
                    </div>
                </div>
            </div>
        </article>
    </div>
</template>
