<script setup>
import ArrowLink from '@/Components/ArrowLink.vue';

const emit = defineEmits(['close']);

const close = () => emit('close');
</script>

<template>
    <div class="lg:text-primary">
        <ArrowLink
            :href="route('product-finder.index')"
            @click="close"
        >
            {{ $t('navigation.product_finder') }}
        </ArrowLink>

        <ArrowLink
            :href="route('wiki')"
            @click="close"
        >
            {{ $t('navigation.wiki') }}
        </ArrowLink>

        <ArrowLink
            :href="route('faq')"
            @click="close"
        >
            {{ $t('navigation.faq') }}
        </ArrowLink>
    </div>
</template>
