<script setup>
import { ref, computed, watch } from 'vue';
import { Link, usePage } from '@inertiajs/vue3';
import Logo from '@assets/logos/logo.svg';
import SearchIcon from '@assets/icons/search.svg';
import CartIcon from '@assets/icons/cart.svg';
import AccountIcon from '@assets/icons/user.svg';
import SearchMenu from './SearchMenu.vue';
import AccountMenu from './AccountMenu.vue';
import Menu from './Menu.vue';
import { useCurrentLocale } from '@/Composables/useCurrentLocale';
import { useCart } from '@/Composables/useCart';
import { useCountries } from '@/Composables/useCountries';

const { getCountryNameFromCode } = useCountries();
const currentLocale = computed(() => useCurrentLocale());
const locales = computed(() => usePage().props.config.locales);
const cart = computed(() => useCart());
const cartItems = computed(() => cart.value?.getItemCount() || 0);

const changeLocale = (locale) => location.assign(
    location.href.replace(currentLocale.value.locale, locale)
);

const nav = ref(null);
const menu = ref(null);
const isMobileMenuOpen = ref(false);
const isSubMenuOpen = ref(false);

watch(isMobileMenuOpen, (value) => {
    if (!value) menu.value.closeSubMenus();
});

const handleSubMenu = () => menu.value.handleSubMenu();

const close = () => {
    menu.value.closeSubMenus();
    isMobileMenuOpen.value = false;
};

const scrollY = ref(window.scrollY);
window.addEventListener('scroll', () => {
    scrollY.value = window.scrollY;
});
</script>

<template>
    <div
        class="z-20 fixed top-0 w-full max-w-[100vw]"
        :class="{ 'h-screen bg-black/50': isSubMenuOpen }"
        @click="close"
    >
        <nav
            ref="nav"
            class="header-nav relative text-primary transition-colors"
            :class="{
                'max-lg:h-screen max-lg:bg-primary max-lg:text-primary-content': isMobileMenuOpen,
                'bg-base-100': isSubMenuOpen || scrollY,
                'transition-none': isMobileMenuOpen || isSubMenuOpen,
            }"
            @click.stop
        >
            <section
                class="page-container max-h-full navbar flex-wrap gap-6 p-4"
            >
                <Link
                    class="hover:text-accent transition-colors mr-auto"
                    :href="route('home')"
                    @click="close"
                >
                    <Logo class="h-8" :title="$t('navigation.home')" />
                </Link>

                <div class="lg:order-1 flex items-center gap-4">
                    <div
                        class="flex items-center gap-4"
                        :class="{ 'max-lg:hidden': isMobileMenuOpen }"
                    >
                        <details
                            name="subMenu"
                            @toggle="handleSubMenu(); $refs.search.focus()"
                        >
                            <summary class="menu-item">
                                <SearchIcon
                                    :title="$t('navigation.search')"
                                    class="h-6"
                                />
                            </summary>
                            <SearchMenu ref="search" @close="close" />
                        </details>

                        <Link
                            :href="route('cart.index')"
                            class="menu-item indicator"
                            :class="{ active: route().current('cart.index') }"
                            @click="close"
                        >
                            <CartIcon
                                :title="$t('navigation.cart')"
                                class="h-6"
                            />
                            <span
                                v-if="cartItems"
                                v-text="cartItems"
                                class="indicator-item badge badge-success"
                            ></span>
                        </Link>

                        <details
                            name="subMenu"
                            @toggle="handleSubMenu"
                        >
                            <summary class="menu-item">
                                <AccountIcon
                                    :title="$t('navigation.account')"
                                    class="h-6"
                                />
                            </summary>
                            <AccountMenu @close="close" />
                        </details>

                        <details
                            name="subMenu"
                            class="dropdown dropdown-end w-full border-2 border-current rounded-md hover:text-accent open:!text-primary-content open:border-primary open:bg-primary transition-colors"
                            @toggle="handleSubMenu"
                        >
                            <summary class="menu-item uppercase px-1 !text-base !text-current !transition-none">
                                {{ currentLocale.language }}
                            </summary>
                            <ul class="dropdown-content menu mt-5 rounded-b-box bg-base-100">
                                <li v-for="locale in locales" :key="locale.locale">
                                    <button
                                        class="cursor-pointer text-primary"
                                        :class="{
                                            active: locale.locale === currentLocale.locale,
                                        }"
                                        @click="changeLocale(locale.locale)"
                                    >
                                        {{ $t('language.' + locale.language) }}
                                    </button>
                                </li>
                            </ul>
                        </details>
                    </div>

                    <label class="btn btn-sm btn-ghost p-0 hover:bg-transparent hover:text-accent swap swap-rotate lg:hidden">
                        <!-- this hidden checkbox controls the state -->
                        <input type="checkbox" v-model="isMobileMenuOpen">

                        <!-- hamburger icon -->
                        <svg
                            :title="$t('labels.menu')"
                            class="swap-off fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 512 512"
                        >
                            <path d="M64,384H448V341.33H64M64,128v42.67H448V128Z" />
                        </svg>

                        <!-- close icon -->
                        <svg
                            :title="$t('labels.close')"
                            class="swap-on fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 512 512"
                        >
                            <polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
                        </svg>
                    </label>
                </div>

                <Menu
                    ref="menu"
                    class="max-h-[calc(100vh_-_8rem)] overflow-auto max-lg:w-full max-lg:flex-col max-lg:items-stretch gap-x-6 gap-y-2 lg:mr-12"
                    :class="{ 'max-lg:hidden': !isMobileMenuOpen }"
                    v-model="isSubMenuOpen"
                    @close="close"
                />
            </section>
        </nav>
    </div>
</template>
