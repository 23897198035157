<script setup>
import { computed, ref } from 'vue';
import { usePage, Link } from '@inertiajs/vue3';
import Logo from '@assets/logos/logo.svg';
import FacebookIcon from '@assets/icons/facebook.svg';
import InstagramIcon from '@assets/icons/instagram.svg';
import PaymentIcons from '@/Components/PaymentIcons.vue';
import DestinationCountryModal from '@/Components/DestinationCountryModal.vue';
import { useCountries } from '@/Composables/useCountries';
import { useShopData } from '@/Composables/useShopData';

const socialLinks = usePage().props.config.socialLinks;

const productTypes = computed(() => usePage().props.shop.productTypes.filter(x => x.isMain));
const materials = computed(() => usePage().props.shop.materials.filter(x => x.isMain));

const { getCountryNameFromCode } = useCountries();
const shopData = computed(() => useShopData());
const destinationCountryCode = computed(
    () => shopData.value.destination_country_code,
);
const countryName = computed(() =>
    getCountryNameFromCode(destinationCountryCode.value),
);

const year = new Date().getFullYear();

const destinationCountryModal = ref(null);

const showDestinationCountryModal = () => {
    destinationCountryModal.value?.showModal();
};
</script>

<template>
    <footer class="w-full py-8 lg:py-16 text-white bg-primary">
        <div class="page-container">
            <div class="grid gap-8 lg:grid-cols-4">
                <aside class="max-lg:order-1 lg:col-span-2">
                    <Logo class="w-full h-auto lg:max-w-xs" />
                </aside>
                <PaymentIcons class="lg:hidden justify-center" />
                <nav>
                    <h6 class="footer-title max-lg:hidden">
                        {{ $t('footer.top.left.title') }}
                    </h6>
                    <ul class="space-y-2 font-mono text-xs list-none max-lg:text-center">
                        <li>
                            <Link
                                class="text-accent hover:text-inherit transition-colors"
                                :href="route('shop.index')"
                            >
                                {{ $t('footer.top.left.shop') }}
                            </Link>
                        </li>
                        <li>
                            <Link
                                class="text-accent hover:text-inherit transition-colors"
                                :href="route('services')"
                            >
                                {{ $t('footer.top.left.services') }}
                            </Link>
                        </li>
                        <li>
                            <Link
                                class="text-accent hover:text-inherit transition-colors"
                                :href="route('about')"
                            >
                                {{ $t('footer.top.left.about') }}
                            </Link>
                        </li>
                        <li>
                            <Link
                                class="text-accent hover:text-inherit transition-colors"
                                :href="route('contact')"
                            >
                                {{ $t('footer.top.left.support') }}
                            </Link>
                        </li>
                        <li>
                            <Link
                                class="text-accent hover:text-inherit transition-colors"
                                :href="route('cart.index')"
                            >
                                {{ $t('footer.top.left.cart') }}
                            </Link>
                        </li>
                        <li>
                            <Link
                                class="text-accent hover:text-inherit transition-colors"
                                :href="route('login')"
                            >
                                {{ $t('footer.top.left.login') }}
                            </Link>
                        </li>
                    </ul>
                </nav>
                <nav>
                    <h6 class="footer-title max-lg:hidden">
                        {{ $t('footer.top.right.title') }}
                    </h6>
                    <ul class="space-y-2 font-mono text-xs list-none max-lg:text-center">
                        <li>
                            <Link class="text-accent hover:text-inherit transition-colors" :href="route('faq')">
                                {{ $t('footer.top.right.faq') }}
                            </Link>
                        </li>
                        <li>
                            <Link class="text-accent hover:text-inherit transition-colors" :href="route('wiki')">
                                {{ $t('footer.top.right.wiki') }}
                            </Link>
                        </li>
                        <li>
                            <Link
                                class="text-accent hover:text-inherit transition-colors"
                                :href="route('contact')"
                            >
                                {{ $t('footer.top.right.contact') }}
                            </Link>
                        </li>
                    </ul>
                </nav>
                <nav class="lg:hidden">
                    <h6 class="footer-title max-lg:hidden">
                        {{ $t('footer.bottom.right.legal') }}
                    </h6>
                    <ul class="space-y-2 font-mono text-xs list-none max-lg:text-center">
                        <li>
                            <Link
                                class="text-accent hover:text-inherit transition-colors"
                                :href="route('imprint')"
                            >
                                {{ $t('footer.bottom.right.imprint') }}
                            </Link>
                        </li>
                        <li>
                            <Link
                                class="text-accent hover:text-inherit transition-colors"
                                :href="route('terms_and_conditions')"
                            >
                                {{ $t('footer.bottom.right.terms_and_conditions') }}
                            </Link>
                        </li>
                        <li>
                            <Link
                                class="text-accent hover:text-inherit transition-colors"
                                :href="route('data_protection')"
                            >
                                {{ $t('footer.bottom.right.privacy_policy') }}
                            </Link>
                        </li>
                        <li>
                            <Link class="text-accent hover:text-inherit transition-colors" href="#">
                                {{ $t('footer.bottom.right.cookie_manager') }}
                            </Link>
                        </li>
                        <li>
                            <Link class="text-accent hover:text-inherit transition-colors" href="#">
                                {{ $t('footer.bottom.right.privacy_center') }}
                            </Link>
                        </li>
                    </ul>
                </nav>
                <nav class="lg:hidden">
                    <h6 class="footer-title max-lg:hidden">
                        {{ $t('checkout.destination_country') }}
                    </h6>
                    <ul class="space-y-2 font-mono text-xs list-none max-lg:text-center">
                        <li>
                            <button
                                class="underline"
                                @click="showDestinationCountryModal"
                            >
                                <template v-if="countryName">
                                    {{ countryName }}
                                </template>
                                <template v-else>
                                    {{ $t('checkout.select_destination_country') }}
                                </template>
                            </button>
                        </li>
                    </ul>
                </nav>
                <nav>
                    <div class="flex items-center max-lg:justify-center gap-4">
                        <a
                            v-if="socialLinks.facebook"
                            :href="socialLinks.facebook"
                            rel="noopener noreferrer"
                            target="_blank"
                            class="btn btn-circle btn-accent text-primary hover:bg-primary-content hover:border-primary-content"
                        >
                            <FacebookIcon class="h-12" />
                        </a>
                        <a
                            v-if="socialLinks.instagram"
                            :href="socialLinks.instagram"
                            rel="noopener noreferrer"
                            target="_blank"
                            class="btn btn-circle btn-accent text-primary hover:bg-primary-content hover:border-primary-content"
                        >
                            <InstagramIcon class="h-12" />
                        </a>
                    </div>
                </nav>
            </div>
            <div class="max-lg:hidden divider divider-white"></div>
            <div class="max-lg:hidden grid gap-8 lg:grid-cols-4">
                <nav v-if="productTypes.length" class="lg:col-span-2">
                    <h6 class="footer-title">
                        {{ $t('product-type.product_types') }}
                    </h6>
                    <ul
                        class="space-y-2 font-mono text-xs list-none sm:columns-2 sm:gap-x-8"
                    >
                        <li
                            v-for="productType in productTypes"
                            :key="productType.id"
                        >
                            <Link
                                class="text-accent hover:text-inherit transition-colors"
                                :href="
                                    route('shop.index', {
                                        productTypeIds: [productType.id],
                                    })
                                "
                            >
                                {{ productType.title }}
                            </Link>
                        </li>
                    </ul>
                </nav>
                <nav v-if="materials.length">
                    <h6 class="footer-title">{{ $t('material.materials') }}</h6>
                    <ul class="space-y-2 font-mono text-xs list-none">
                        <li v-for="material in materials" :key="material.id">
                            <Link
                                class="text-accent hover:text-inherit transition-colors"
                                :href="
                                    route('shop.index', {
                                        materialIds: [material.id],
                                    })
                                "
                            >
                                {{ material.title }}
                            </Link>
                        </li>
                    </ul>
                </nav>
                <nav>
                    <h6 class="footer-title">
                        {{ $t('footer.bottom.right.title') }}
                    </h6>
                    <PaymentIcons class="mb-8 max-w-72" />
                    <ul class="space-y-2 font-mono text-xs list-none">
                        <li>
                            <Link
                                class="text-accent hover:text-inherit transition-colors"
                                :href="route('imprint')"
                            >
                                {{ $t('footer.bottom.right.imprint') }}
                            </Link>
                        </li>
                        <li>
                            <Link
                                class="text-accent hover:text-inherit transition-colors"
                                :href="route('terms_and_conditions')"
                            >
                                {{ $t('footer.bottom.right.terms_and_conditions') }}
                            </Link>
                        </li>
                        <li>
                            <Link
                                class="text-accent hover:text-inherit transition-colors"
                                :href="route('data_protection')"
                            >
                                {{ $t('footer.bottom.right.privacy_policy') }}
                            </Link>
                        </li>
                        <li>
                            <Link class="text-accent hover:text-inherit transition-colors" href="#">
                                {{ $t('footer.bottom.right.cookie_manager') }}
                            </Link>
                        </li>
                        <li>
                            <Link class="text-accent hover:text-inherit transition-colors" href="#">
                                {{ $t('footer.bottom.right.privacy_center') }}
                            </Link>
                        </li>
                    </ul>

                    <h6 class="mt-8 footer-title">
                        {{ $t('checkout.destination_country') }}
                    </h6>
                    <ul class="space-y-2 font-mono text-xs list-none">
                        <li>
                            <button
                                class="underline"
                                @click="showDestinationCountryModal"
                            >
                                <template v-if="countryName">
                                    {{ countryName }}
                                </template>
                                <template v-else>
                                    {{ $t('checkout.select_destination_country') }}
                                </template>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <div class="page-container mt-8 lg:mt-16">
            <div
                class="grid lg:grid-cols-4 gap-x-8 gap-y-2 font-mono text-xs"
            >
                <p class="uppercase">
                    © {{ $t('footer.all_rights_reserved') }} {{ year }}
                </p>
                <p>
                    <i18n-t keypath="footer.website_by" scope="global">
                        <template #brisk>
                            <a
                                href="#"
                                rel="noopener noreferrer"
                                target="_blank"
                                class="text-accent hover:text-inherit transition-colors"
                            >Studio Brisk</a>
                        </template>
                        <template #dotbite>
                            <a
                                href="https://dotbite.at"
                                rel="noopener noreferrer"
                                target="_blank"
                                class="text-accent hover:text-inherit transition-colors"
                            >Dotbite</a>
                        </template>
                    </i18n-t>
                </p>
            </div>
        </div>
        <DestinationCountryModal ref="destinationCountryModal" />
    </footer>
</template>
