<script setup>
import ArrowUp from '@assets/icons/arrow_up.svg';

const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};
</script>

<template>
    <button
        type="button"
        class="fixed bottom-0 right-0 flex items-center justify-center w-12 h-12 lg:w-16 lg:h-16 m-4 p-0 bg-white rounded-full btn"
        @click="scrollToTop"
    >
        <ArrowUp class="relative w-6 h-6 md:w-8 md:h-8 text-primary" />
    </button>
</template>
